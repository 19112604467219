import React from "react"
import { Layout } from "../../components/Layout"
import { graphql } from "gatsby"
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"
import {
  Top,
  Left,
  Right,
  HtmlContainer,
  Background,
  ImageBackground,
  Content,
} from "./style"

export default function GeneratedPage({ data }) {
  const content = data.contentfulBlogPost.content.childMarkdownRemark.html
  const shortDescription =
    data.contentfulBlogPost.shortDescription.childMarkdownRemark.html
  const { title, thumbnail } = data.contentfulBlogPost
  const image = getImage(thumbnail)
  return (
    <Layout>
      <Top>
        <Background>
          <StaticImage
            src="../../images/generated-page-background.png"
            alt="lines"
            placeholder="blurred"
            objectFit="fill"
            style={{
              width: "100%",
            }}
          />
        </Background>
        <Content>
          <Left>
            <ImageBackground>
              <StaticImage
                src="../../images/gradient-texture.png"
                alt="gradient"
                placeholder="blurred"
                style={{ height: "100%" }}
              />
            </ImageBackground>
            <GatsbyImage image={image} alt="alt" />
          </Left>
          <Right>
            <h2>{title}</h2>
            <div dangerouslySetInnerHTML={{ __html: shortDescription }}></div>
          </Right>
        </Content>
      </Top>
      <HtmlContainer dangerouslySetInnerHTML={{ __html: content }} />
    </Layout>
  )
}

export const query = graphql`
  query BlogPost($slug: String) {
    contentfulBlogPost(slug: { eq: $slug }) {
      shortDescription {
        childMarkdownRemark {
          html
        }
      }
      title
      thumbnail {
        gatsbyImageData
      }
      content {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`
